$baseDarkColor: var(--base-dark-color);
$baseGrayColor: var(--grey-color);
$baseGrayColor5: var(--grey-color-5);

$errorColor: var(--base-danger-color);

.form-items {
    margin-bottom: 1.5rem;

    .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating):not(.input-group-text) {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .form-label {
        font-size: 15px;
        color: $baseGrayColor;
    }

    .form-control,
    .form-select {
        // background-color: rgba(0, 0, 0, 0);
        outline: none;
    
        border: 2px solid rgba(0, 0, 0, 0);
        border-radius: 6px;
    
        padding: 0.5rem 1rem;
    
        font-size: 14px;
    
        &.is-filled,
        &:focus,
        &:active,
        &:hover {
            outline: 0;
            box-shadow: none;
            transition: all 0.5s ease;
        }
    
        &.is-invalid {
            border-color: $errorColor;

            &:focus,
            &:active,
            &:hover {
                box-shadow: none;
                transition: all 0.5s ease;
            }
        }
    }

    .form-control {
        &.file-upload {
            border-style: dashed;
            max-width: unset;

            min-height: 150px;
            max-height: 600px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;

            color: $baseGrayColor;

            .icon {
                font-size: 18px;
                margin-bottom: 10px;
            }

            .label {
                font-family: Inter;
                font-weight: 400;
                font-size: 15px;
                margin: 0;             
            }

            .file-upload-image {
                // max-height: 500px;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .types {
        font-family: Inter;
        font-weight: 400;
        font-size: 13px;
        color: $baseGrayColor;
        padding: 10px 0;

        border-bottom: 1px solid $baseGrayColor5;
    }

    .invalid-feedback {
        color: $errorColor;
    }
}
