$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);
$baseGrayColor: var(--grey-color);

.payment-direction-form {
    .payment-direction-form-title {
        font-size: 1.2rem;
        font-weight: 800;
        margin-top: 20px;
        margin-bottom: 15px;

        font-family: Inter;
    }

    .payment-direction-form-container {
        padding: 1rem 0 2rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;

        .payment-direction-form-option {
            display: flex;
            flex-direction: column;
            
            .description {
                color: $baseGrayColor;
                font-size: 13px;
            }
        }
    }
}