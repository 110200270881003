.shopping-cart-page {
    // padding: 2rem;
    // padding-top: 60px;
    padding: 56px 0 0 0;

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    #section-1 {

    }

    #section-2 {
        
    }

    #section-3 {
        width: 100%;
    }
    
    @media screen and (max-width: 1035px) {
        flex-direction: column-reverse;
    }

    @media (max-width: 768px) {
        // padding-top: 120px;
    }
}

