$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

$testimonyTextColor: var(--grey-color);

.testimony {
    background-color: $basePrimaryColor;

    width: 100%;
    // padding-top: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4rem 5rem 4rem 5rem;
    gap: 40px;
    // height: 30vw;

    .testimony-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        width: 100%;

        .testimony-header-content {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .testimony-title {
                color: $baseSecondaryColor;
                text-transform: uppercase;
                font-weight: 800;
            }

            .testimony-desc {
                color: white;
            }
        }
    }

    .testimony-list {
        display: flex;
        align-items: flex-start;
        justify-content: stretch;
        flex-wrap: wrap;
        gap: 30px;

        .testimony-item {
            width: 200px;
            min-height: 240px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 30px;

            width: 30%;

            background-color: white;
            border: 1px solid $baseSecondaryColor;
            border-radius: 20px;

            padding: 20px;

            .testimony-item-image {
                // width: 50px;
                max-height: 50px;
                margin-top: 20px;
            }

            .testimony-item-desc {
                font-size: 14px;
                font-weight: 500;
                color: $testimonyTextColor;
            }

            .testimony-item-name {
                font-size: 16px;
                color: $basePrimaryColor;
                font-weight: 400;
            }
        }
    }
}

@media (max-width: 778px) {
    .testimony {
        padding: 4rem 1rem 4rem 1rem;

        .testimony-header {
            flex-direction: row-reverse;
            gap: 25px;

            // .testimony-header-icon {
            //     width: 80px;

            //     img {
            //         width: 100%;
            //     }
            // }
            
            .testimony-header-content {
                .testimony-desc {
                    text-align: justify;
                    text-justify: none;
                    font-size: 15px;
                }
            }
        }

        .testimony-list {
            flex-direction: column;

            .testimony-item {
                width: 100%;

                .testimony-item-desc {
                    text-align: justify;
                    text-justify: none;
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .testimony {
        .testimony-header {
            .testimony-header-content {
                gap: 10px;
            }
        }
    }
}