$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

.about {
    background-color: $baseShadeColor;
    color: white;

    width: 100%;
    // padding-top: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 5rem 5rem 5rem;
    gap: 40px;
    // height: 30vw;

    overflow: hidden;

    .about-title {
        color: $baseSecondaryColor;
        text-transform: uppercase;
        font-weight: 800;
        // font-size: 2.4rem;
        text-align: center;
    }

    .about-desc {
        text-align: center;

        p {
            font-weight: 400;
        }
    }
}

@media (max-width: 778px) {
    .about {    
        padding: 2rem 1rem 5rem 1rem;

        .about-desc {
            text-align: justify;
            text-justify: none;
            font-size: 15px;
        }
    }
}

@media (max-width: 520px) {
    .about {
        gap: 20px;
        padding-bottom: 3rem;
    }
}