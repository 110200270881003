$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

$baseSuccessColor: var(--base-success-color);

.shipping-form {
    .shipping-form-title {
        font-size: 1.2rem;
        font-weight: 800;
        margin-top: 20px;
        margin-bottom: 15px;

        font-family: Inter;
    }

    .shipping-form-container {
        padding: 1rem 0 2rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;

        .shipping-form-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            .description {
                font-size: 15px;

                &.free {
                    color: $baseSuccessColor;
                }
            }
        }
    }
}