$baseDarkColor: var(--base-dark-color);
$baseGrayColor: var(--base-gray-color);

$baseLightColor: var(--base-light-color);

$baseGrayColor: var(--grey-color-5);

$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

$stepColor: var(--grey-color);
$activeStepColor: $basePrimaryColor;
$checkColor: var(--grey-color-2);
$activeCheckColor: $baseSecondaryColor;

.checkout-section {
    width: 40%;
    height: 100%;
    // height: 500px;
    min-width: 400px;
    min-height: 800px;

    background-color: $baseGrayColor;

    padding: 60px 1.5rem 1.5rem;

    .checkout-section-title {
        font-weight: 800;
        font-size: 1.5rem;
        color: $basePrimaryColor;
        font-size: 38px;
    }

    .checkout-detail {
        width: 100%;
        height: 100%;

        margin: 2rem 0;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;

        .steps {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            hr {
                width: 100%;
                height: 1px;
            }

            .step-item {
                color: $stepColor;
                font-size: 22px;

                &.active {
                    color: $activeStepColor;
                }
            }

            .step-icon {
                color: $checkColor;

                &.active {
                    color: $activeCheckColor;
                }
            }
        }

        .step-component {
            
            .step-address-button {
                float: right;
                width: 48%;
                // min-width: 250px;

                text-transform: capitalize;
            }

            .step-address-back-button {
                float: right;
                width: 48%;
                // min-width: 250px;
                border: 2px solid $basePrimaryColor;
                background-color: $basePrimaryColor;
                color: $baseLightColor;

                margin-right: 10px;

                text-transform: capitalize;

                &:hover, &:active {
                    background-color: rgba(0, 0, 0, 0);
                    color: $baseSecondaryColor;
                    border-color: $baseSecondaryColor;
                }
            }
        }
    }

    .checkout-button {
        width: 100%;
        font-size: 15px;
        padding: 10px 10px;
    }

}

@media screen and (max-width: 1035px) {
    .checkout-section {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .checkout-section {
        .checkout-detail {
            .step-component {
                .step-address-button {
                    width: 100%;
                    margin-left: 0;
                    float: none;
                    margin-bottom: 10px;
                }

                .step-address-back-button {
                    width: 100%;
                    float: none;
                    margin-top: 10px;
                }
            }
        }
    }
} 