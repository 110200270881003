$baseLightColor2: var(--base-light-color-2);
$borderColor: var(--grey-color-3);
$hoverColor: var(--grey-color-5);

$formButtonBackgroundColor: var(--base-light-color);
$formButtonColor: var(--base-shade-color);

$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

.login-page {
    display: flex;
    align-items: flex-start;
    justify-content: stretch;

    .login-image {
        width: 100%;
        min-height: 100%;

        img {
            width: 100%;
            min-height: 100%;
        }

        video {
            width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }

    .login-container {
        width: 100%;
        height: 80%;

        padding: 3rem 3rem;

        background-color: $baseLightColor2;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;

        .login-page-title {
            font-weight: 600;
            font-size: 48px;
            font-family: Barlow;
            margin-bottom: 30px;
        }

        .login-select-menu {
            display: flex;
            justify-content: stretch;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid $borderColor;

            .login-select-menu-item {
                width: 100%;
                text-align: center;
                padding: 10px;

                border: none;
                background-color: unset;
                border-bottom: 2px solid rgba(0, 0, 0, 0);

                &:hover {
                    background-color: $hoverColor;
                }

                &.active {
                    font-weight: 700;
                    border-bottom: 2px solid $basePrimaryColor;
                }
            }
        }

        .login-item {
            

            &.login-btn {
                width: 50%;
                min-width: 200px;
                font-size: 16px;
                box-shadow: none;

                background-color: $formButtonBackgroundColor;
                color: $formButtonColor;

                border: 2px solid $borderColor;
                border-radius: 10px;
                font-weight: 500;
                text-transform: capitalize;

                &:hover {
                    
                }
            }
        }
    }

    @media (max-width: 1200px) {
        position: relative;

        .login-image {

        }

        .login-container {
            background: rgba(255, 255, 255, 0.2);
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            
            .login-select-menu {
                .login-select-menu-item {
                    width: 100%;
                    text-align: center;
                    padding: 10px;

                    border: none;
                    background-color: unset;
                    border-bottom: 2px solid rgba(0, 0, 0, 0);

                    &:hover {
                        background-color: rgba(0, 0, 0, 0);
                    }

                    &.active {

                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .login-image {
            // display: none;
            width: auto;
            height: 100vh;
            
            
        }

        .login-container {
            // position: inherit;
            .login-page-title {
                font-size: 36px;
                margin-bottom: 15px;

            }

            .login-item {
            

                &.login-btn {
                    width: 100%;
                }
            }
        }
    }
}