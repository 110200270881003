$basePrimaryColor: var(--base-primary-color);

.header {
    width: 100%;
    // padding-top: 30vh;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 5rem 15vh 5rem;
    // height: 30vw;

    overflow: hidden;

    .header-caption {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: 15px;

        .header-caption-title {
            color: $basePrimaryColor;
            text-transform: uppercase;
            font-weight: 800;
        }

        // .header-caption-text {

        // }

        .header-caption-button-container {
            display: flex;
            gap: 10px;
        }
    }

    .header-image {
        width: 70%;
        max-width: 700px;
        max-height: 700px;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 50%;

        img {
            width: 100%;
        }
    }

    .photo-carousel-container {
        img {
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    .header {
        padding: 0 2rem 15vh 2rem;
        flex-direction: column;

        .header-caption {
            // width: 100%;
            .header-caption-text {
                font-size: 16px;
            }

            .header-caption-button {
                width: 100%;
            }
        }

        .header-image {
            width: 100%;
            max-width: unset;
        }

        .photo-carousel-container {
            width: 70%;
            .photo-carousel-holder {
                .photo-container {
                    img {
                        width: 100%;
                    }
                }
            }

            .react-multiple-carousel__arrow {
                // width: 30px;
                // height: 30px;

                &::before {
                    // font-size: 15px;
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .header {
        .header-caption {
            .header-caption-text {
                text-align: justify;
                // Method 1
                // text-justify: none;
                
                // Method 2
                text-justify: inter-word;
                word-spacing: -0.5px;
                hyphens: auto;

                // Method 3
                // text-align-last: justify;
            }
        }

        .photo-carousel-container {
            width: 100%;

            .react-multiple-carousel__arrow {
                width: 35px;
                height: 35px;

                &::before {
                    font-size: 15px;
                }
            }
        }
    }
}