$footerBackgroundColor: var(--dark-color);
$buttonBorderColor: var(--dark-color-2);
$textColor: var(--base-light-color);
$copyrightTextColor: var(--grey-color-2);

.footer-contact {
    width: 100%;
    background-color: $footerBackgroundColor;
    color: $textColor;
    
    /* Across browsers req */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    padding: 3rem;
    padding-bottom: 6.5rem;
    
    .footer-contact-title {
        font-family: NotoSans;
        font-weight: 200;
    }
    
    .footer-contact-socmed {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .socmed-item {
            border: 1px solid $buttonBorderColor;
            width: 70px;
            height: 70px;

            font-size: 14px;

            display: flex;
            align-items: center;
            justify-content: center;

            text-decoration: none;
            color: inherit;
        }
    }

    .footer-copyright {
        color: $copyrightTextColor;
        font-weight: 200;
        font-size: 14px;
        text-align: center;
    }

    .footer-menu {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;

        .footer-menu-link,
        .footer-menu-separator {
            color: $copyrightTextColor;
            font-weight: 200;
            font-size: 14px;
            text-decoration: none;
        }

        .footer-menu-link {
            text-align: center;
        }

        .footer-menu-link:hover,
        .footer-menu-link:active,
        .footer-menu-link:focus {
            opacity: 0.5;
        }
    }
}