$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseLightColor: var(--base-light-color);

.brands {
    background-color: $baseSecondaryColor;
    width: 100%;
    // padding-top: 30vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    // margin-top: 40vh;
    padding: 30vh 5rem 5rem 5rem;
    gap: 30px;

    overflow: hidden;
    position: relative;

    .brands-border-top {
        position: absolute;
        top: -0.5px;
        left: 0;
        height: 40px;
        width: 100%;

        display: flex;
        flex-direction: row;
        
        .brands-top {
            background-color: white;
            height: 100%;
            width: 10%;
            // width: 200px;
            
            clip-path: polygon(100% 0,50% 100%,0 0);
        }
    }

    .brands-header {
        display: flex;
        // align-items: center;
        justify-content: center;
        gap: 10px;

        .brands-header-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            width: 70%;

            .brand-title {
                color: $basePrimaryColor;
                text-transform: uppercase;
                font-weight: 800;
                // font-size: 2.4rem;
                // text-align: center;
            }
            
            .brand-desc {
                font-size: 1.1rem;
                text-align: center;
            }
        }

    }

    .brands-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-evenly;
        gap: 10px;

        .brand-item {
            width: 130px;
            height: 130px;

            background-color: $baseLightColor;
            border-radius: 20px;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 70%;
            }
        }
    }

    .brands-see-more {
        font-weight: 700;
        font-style: italic;
        font-size: 22px;
        width: 100%;
        
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}

@media (max-width: 778px) {
    .brands {    
        padding: 30vh 1rem 5rem 1rem;

        .brands-header {
            justify-content: stretch;

            .brands-header-icon {
                display: none;    
            }

            .brands-header-content {
                width: 100%;
                gap: 15px;
            
                .brand-title {
                    width: 90%;
                }

                .brand-desc {
                    text-align: justify;
                    text-justify: none;
                    width: 90%;
                    font-size: 15px;
                }
            }
        }

        .brands-list {
            gap: 5px;
    
            .brand-item {
                width: 90px;
                height: 90px;
                border-radius: 10px;
            }
        }
    }
}

@media (max-width: 520px) {
    .brands {
        padding: 21vh 1rem 5rem 1rem;

        .brands-header {
            .brands-header-content {
                gap: 10px;
            }
        }
    }
}