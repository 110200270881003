$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

.values {
    position: absolute;

    width: 100%;
    // padding-top: 30vh;
    display: flex;
    align-items: space-between;
    justify-content: center;
    width: 100%;
    padding: 0 5rem 15vh 5rem;
    // height: 30vw;

    transition: 0.3s ease-in-out;

    z-index: 900;

    .values-box {
        width: 100%;
        // height: 300px;
        background-color: $baseShadeColor;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4rem;

        padding: 2rem 5rem;

        border-radius: 20px;

        .values-item {
            color: white;
            display: flex;
            flex-direction: column;
            height: 100%;
            gap: 25px;

            .values-item-image {
                width: 50px;
            }

            .values-item-title {
                font-family: Inter;
                font-size: 20px;
                font-weight: 700;
                color: $baseSecondaryColor;
            }

            .values-item-desc {
                font-size: 17px;
                max-width: 300px;
            }
            
            .values-item-link {
                color: white;
                font-weight: 600;
                transition: all 0.3s ease;

                &:hover, &:active, &:focus {
                    color: $baseSecondaryColor;
                }
            }
        }
    }

    .value-carousel-container {
        width: 100%;
        // height: 300px;
        background-color: $baseShadeColor;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4rem;

        padding: 2rem 5rem;

        border-radius: 20px;

        .react-multiple-carousel__arrow {
            min-width: 0;
            min-height: 0;

            background-color: rgba(0, 0, 0, 0);

            width: 35px;
            height: 35px;

            // position: fixed;
            z-index: 9999;

            &--left {
                left: -5px;
            }

            &--right {
                right: -5px;
            }

            &::before {
                font-size: 15px;
                font-weight: 800;
            }
        }

        .values-item {
            color: white;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: auto;
            gap: 25px;

            .values-item-image {
                width: 50px;
            }

            .values-item-title {
                font-family: Inter;
                font-size: 20px;
                font-weight: 700;
                color: $baseSecondaryColor;
            }

            .values-item-text-wrapper {
                display: grid;
                grid-template-columns: 1fr;
            }

            .values-item-desc {
                font-size: 17px;
                max-width: 300px;
                text-align: justify;
                // text-justify: none;
                
                // text-justify: inter-word;
                // word-spacing: -0.5px;
                // hyphens: auto;
            }
            
            .values-item-link {
                color: white;
                font-weight: 600;
                transition: all 0.3s ease;

                &:hover, &:active, &:focus {
                    color: $baseSecondaryColor;
                }
            }
        }
    }
}

@media (max-width: 969px) {
    .values {
        padding: 0 2rem 15vh 2rem;

        .values-box {
            padding: 2rem;
            gap: 1rem;
            overflow: auto;
            width: 100%;

            .values-item {
                gap: 15px;
                // min-width: 200px;
    
                // .values-item-image {

                // }
    
                .values-item-title {
                    font-size: 15px;
                }
    
                .values-item-desc {
                    font-size: 12px;
                    max-width: unset;
                }

            }
        }
    }
}

@media (max-width: 900px) {
    .values {
        .value-carousel-container {
            padding: 0;

            .values-item {
                width: 100%;
                padding: 2rem;
            
                .values-item-desc {
                    width: 100%;
                    max-width: unset;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .values {
        .value-carousel-container {
            .values-item {
                justify-content: flex-start;
                gap: 15px;

                .values-item-title {
                    // max-width: 250px;
                    font-size: 18px;
                }

                .values-item-desc {
                    // max-width: 230px;
                    font-size: 13px;
                }
            }
        }
    }
}