$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

.packaging-catalog {
    width: 100%;
    // padding-top: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4rem 5rem 5rem 5rem;
    gap: 40px;
    // height: 30vw;

    .packaging-catalog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        width: 100%;

        .packaging-catalog-header-content {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .packaging-catalog-title {
                color: $basePrimaryColor;
                text-transform: uppercase;
                font-weight: 800;
            }
        }
    }

    .packaging-catalog-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 30px;
        max-width: 1200px;

        .packaging-catalog-item {
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            text-decoration: none;
            color: inherit;

            .packaging-catalog-item-image-container {
                width: 80%;
                aspect-ratio: 1/1;
                overflow: hidden;
                position: relative;
                border-radius: 50%;

                .packaging-catalog-item-image {
                    width: 100%;
                    // height: 0;
                    // padding-bottom: 80%;
                }

                .detail-box {
                    opacity: 0;

                    position: absolute;
                    bottom: 0;

                    width: 100%;
                    height: 60px;

                    background-color: $baseSecondaryColor;
                    color: $basePrimaryColor;
                    font-weight: 700;
                    border-radius: 50%;

                    text-align: center;
                    padding-top: 15px;
                }
            }

            .packaging-catalog-item-name {
                text-align: center;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
            }

            &:hover, &:active {
                .packaging-catalog-item-image-container {
                    .detail-box {
                        opacity: 1;
                        transition: 0.5s ease-in;
                    }
                }

                .packaging-catalog-item-name {
                    color: $basePrimaryColor;
                }
            }
        }
    }
}

@media (max-width: 778px) {
    .packaging-catalog {    
        padding: 4rem 1rem 5rem 1rem;

        .packaging-catalog-header {
            flex-direction: row-reverse;
            gap: 25px;

            .packaging-catalog-header-content {
                .packaging-catalog-desc {
                    text-align: justify;
                    text-justify: none;
                    font-size: 15px;
                }
            }

            // .packaging-catalog-header-icon {
            //     // display: none;
            //     width: 150px;

            //     img {
            //         width: 100%;
            //     }
            // }
        }

        .packaging-catalog-list {
            gap: 30px 10px;
            max-width: unset;

            .packaging-catalog-item {
                width: 120px;
                gap: 15px;

                .packaging-catalog-item-name {
                    font-size: 12px;
                }

                &:hover, &:active {
                    .packaging-catalog-item-image-container {
                        .detail-box {
                            opacity: 1;
                            transition: 0.5s ease-in;
                        }
                    }

                    .packaging-catalog-item-name {
                        color: $basePrimaryColor;
                    }
                }
            }
        }
    }
}

@media (max-width: 520px) {
    .packaging-catalog {
        .packaging-catalog-header {
            .packaging-catalog-header-content {
                gap: 10px;
            }
        }
    }
}