* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: var(--primary-text-color); */
  font-family: 'Inter';
  font-weight: 500;
}

.App {
  background-color: var(--base-light-color);
  color: var(--primary-text-color);
  position: relative;
  min-height: 100vh;
  height: fit-content;
}

.App.dark-mode {
  background-color: #222222;
  color: #FFF;
}

.App .hide {
  visibility: hidden;
  opacity: 0;
}

.base-bg-dark {
  background-color: var(--base-bg-dark);
}

h1, h2, h3, h4, h5, h6 {
  font-family: Barlow;
}

/* Fonts */

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
}

/* Static 'Inter' Font */

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Black.ttf');
  font-weight: 900;
}

/* Static 'Barlow' Font */

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Barlow';
  src: url('./Fonts/Barlow/Barlow-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

/* Static 'NotoSans' Font */

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./Fonts/NotoSans/NotoSans-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}