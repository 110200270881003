$formInputBackgroundColor: var(--form-input-background-color);
$formInputColor: var(-form-input-color);

.otp-form-container {
    font-weight: 500;
    width: 100%;

    .otp-form {
        .form-label {
            // font-size: 20px;
        }

        .form-control,
        .form-select {
            // font-size: 20px;
            background-color: $formInputBackgroundColor;
            color: $formInputColor;
        }
    }

    .btn-otp-form {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .otp-form-container {
        .form-label {
            color: #000;
        }
    }
}