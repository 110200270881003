$baseGrayColor: var(--grey-color);
$baseGrayColor2: var(--grey-color-2);

$baseLightColor: var(--base-light-color);

$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

.payment-success-result-page {
    width: 100%;
    // border: 10px solid black;
    height: 100vh;
    // margin: 75px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .payment-result-ticket {
        display: flex;
        flex-direction: column;
        align-items: center;

        position: relative;
        width: 385px;
        height: 500px;
        
        img {
            width: 385px;
        }

        .payment-result-ticket-info-container {
            width: 385px;
            position: absolute;
            top: 0;

            .payment-result-ticket-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                color: $baseLightColor;

                padding: 92px 50px 50px;

                .payment-result-ticket-info-title {
                    font-size: 18px;
                    text-align: center;
                    font-weight: 600;
                    max-width: 200px;
                    font-family: Inter;
                }

                .payment-result-ticket-info-description {
                    font-size: 12px;
                    font-weight: 400;
                    text-align: center;
                }

                hr {
                    width: 100%;
                }

                .payment-result-ticket-info-price {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    margin-top: 15px;
                    margin-bottom: 15px;

                    .price-label {
                        font-size: 12px;
                        font-weight: 400;
                    }

                    .price-value {
                        font-size: 22px;
                        font-weight: bold;
                    }
                }

                .payment-result-ticket-info-detail {
                    width: 100%;

                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    row-gap: 10px;
                    column-gap: 10px;

                    .payment-result-ticket-info-detail-block {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;

                        border: 1px solid rgba(255, 255, 255, 0.16);
                        padding: 11px;
                        border-radius: 5px;

                        .detail-label {
                            font-size: 11px;
                            color: $baseSecondaryColor;
                        }

                        .detail-value {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }

    .btn-back-home {
        margin-top: 1rem;

        text-decoration: none;
        color: #000;
        background-color: $baseLightColor;

        border-radius: 40px;
        padding: 1rem 3rem;
        font-size: 18px;
        font-weight: 600;

        text-transform: uppercase;

        transition: all 0.2s ease;

        &:hover {
            // background-color: $baseDarkColor3;
            // border-color: $baseDarkColor3;
        }
    
        &.disabled {
            background-color: $baseGrayColor;
        }
    }
}