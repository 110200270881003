$baseGrayColor: var(--grey-color-5);
$baseLightColor: var(--base-light-color);

.custom-design-form-container {
    font-weight: 500;

    .custom-design-form-title {
        font-size: 1.2rem;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .custom-design-form {
        .form-control,
        .form-select {
            font-size: 13px;
            font-weight: 500;
            border: 1px solid $baseGrayColor;
        }
    }

    .btn-payment-confirm-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-payment-confirm {
            // width: 100%;
            text-transform: capitalize;
            min-width: 200px;
            padding: 12px 12px;
        }
    }
}

.custom-design-form-toast {
    color: $baseLightColor;
}