$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

$baseGrayColor: var(--base-gray-color);
$baseGrayColor2: var(--base-gray-color-2);

$baseLightColor: var(--base-light-color);

$baseDangerColor: var(--base-danger-color);

.checkout-box {
    position: fixed;
    width: 95%;
    bottom: 100px;
    left: 2.5%;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $baseShadeColor;
    color: $baseLightColor;

    padding: 16px;
    border-radius: 20px;

    .checkout-product {
        display: flex;
        align-items: center;
        gap: 80px;

        .checkout-product-first-section {
            display: flex;
            align-items: center;
            gap: 20px;

            .checkout-product-image {
                width: 82px;
                // height: 82px;
                aspect-ratio: 1/1;
                overflow: hidden;
                border-radius: 50%;
    
                img {
                    width: 100%;
                }
            }
    
            .checkout-product-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                gap: 10px;
                
                .checkout-product-info-name {
                    font-size: 17px;
                    font-weight: 700;
                }
    
                .checkout-product-info-variant {
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }

        .checkout-product-second-section {
            display: flex;
            align-items: center;
            gap: 20px;

            .checkout-product-qty {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
    
                gap: 9px;
    
                .checkout-product-qty-label {
                    font-size: 14px;
                }
    
                .checkout-product-qty-input-container {
                    color: #000;
                }
            }

            .checkout-product-pricing {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
    
                .checkout-product-pricing-label {
                    font-size: 14px;
                }
    
                .checkout-product-pricing-value {
                    color: $baseSecondaryColor;
                    
                    .price {
                        font-weight: 700;
                        font-size: 26px;
                        margin-right: 5px;
                    }
    
                    .price-per-unit {
                        margin-left: 5px;
                        font-size: 11px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .checkout-button-container {
        .btn-add-to-cart {
            // background-color: $baseShadeColor;
            // color: $baseSecondaryColor;
            // border: 1px solid $baseSecondaryColor;

            font-size: 19px;

            padding: 18px 21px;
            text-transform: capitalize;
            min-width: 250px;

            &:hover, &:active {
                opacity: 0.7;
            }
        }
    }

    @media (max-width: 1135px) {
        flex-direction: column;
        gap: 20px;
    }

    @media (max-width: 870px) {
        top: 60px;
        bottom: unset;

        .checkout-product {
            flex-direction: column;
            gap: 20px;
        }

        .checkout-button-container {
            width: 100%;

            .btn-add-to-cart {
                width: 100%;
            }
        }
    }

    @media (max-width: 576px) {
        .checkout-product {
            .checkout-product-second-section {
                // flex-direction: column;

                .checkout-product-pricing {
                    align-items: flex-end;

                    .checkout-product-pricing-label {}

                    .checkout-product-pricing-value {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        .price {
                            font-size: 20px;
                            margin-right: 0;
                        }

                        .price-per-unit {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}