.shopping-cart-empty {
    width: 100%;
    height: 100%;
    min-height: 80vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .empty-cart-text {
        font-size: 20px;
        font-weight: 500;
    }
}