$formInputBackgroundColor: var(--form-input-background-color);
$formInputColor: var(-form-input-color);

.login-form-container {
    font-weight: 500;
    width: 100%;

    .login-form {
        .form-label {
            // font-size: 20px;
        }

        .form-control,
        .form-select {
            // font-size: 20px;
            background-color: $formInputBackgroundColor;
            color: $formInputColor;
        }
    }

    .btn-login-form {
        width: 30%;
    }
}

@media (max-width: 1200px) {
    .login-form-container {
        .form-label {
            color: #000;
        }
    }
}

@media (max-width: 768px) {
    .login-form-container {
        .btn-login-form {
            width: 100%;
        }
    }
}