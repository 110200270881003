$basePrimaryColor: var(--base-primary-color);

.product-page {
    padding-top: 100px;
    margin-bottom: 50px;    

    min-height: 60vh;

    .btn-back-container {
        padding: 20px 3rem;
        
        .btn-back {
            text-decoration: none;
            color: #000;

            width: fit-content;

            display: flex;
            align-items: center;
            gap: 5px;

            font-size: 18px;

            padding: 5px 10px;
            border-radius: 30px;

            .btn-back-icon {
                font-size: 20px;
                color: $basePrimaryColor;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
                transition: all 0.5s ease-in-out;
            }
        }
    }
}