$basePrimaryColor: var(--base-primary-color);

.order-detail-page {
    padding: 4rem;
    padding-top: 125px;

    @media (max-width: 768px) {
        padding-top: 90px;
    }

}

// Modal
.payment-confirmation-modal {
    .modal-title {
        font-weight: 700;
        color: $basePrimaryColor;
    }
}