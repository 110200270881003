$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

.btn-custom-secondary {
    font-family: 'Inter';

    border: 0;
    outline: 0;
    // width: 48%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease;
    
    &.sm {
        gap: 8px;

        border-radius: 50px;
    
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    
        padding: 0.5rem 1.6rem;
    }

    &.md {
        gap: 8px;
        
        border-radius: 50px;
        
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        padding: 1.2rem 1rem;
    }
    
    background-color: $baseShadeColor;
    color: $baseSecondaryColor;
    border: 1px solid $baseSecondaryColor;

    &:disabled, &.disabled {
        opacity: 0.7;
        background-color: rgba(0, 0, 0, 0);
        border-color: $baseShadeColor;
        border-width: 1px;
        cursor: initial;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border-color: $baseShadeColor;
    }
}