.address-form {
    // min-width: 700px;
    width: 95%;
    
    margin-bottom: 30px;

    .address-form-title {
        font-size: 1.2rem;
        font-weight: 800;
        margin-top: 20px;
        margin-bottom: 15px;
        font-family: Inter;
    }

    @media screen and (max-width: 1035px) {
        width: 100%;
    }
}