$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

.order-instruction {
    width: 100%;
    // padding-top: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4rem 5rem 4rem 5rem;
    gap: 40px;
    // height: 30vw;

    .order-instruction-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        width: 100%;

        .order-instruction-header-content {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .order-instruction-title {
                color: $basePrimaryColor;
                text-transform: uppercase;
                font-weight: 800;
            }

            // .order-instruction-desc {
                
            // }
        }
    }

    .order-instruction-content {
        display: flex;
        align-items: center;
        gap: 20px;

        .order-instruction-content-image {
            // width: 60%;
            max-width: 600px;

            img {
                width: 100%;
            }
        }

        .order-instruction-content-instruction {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 30px;

            padding: 20px;

            .instruction-list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 20px;

                .instruction-item {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                    justify-content: center;

                    .instruction-item-index {
                        min-width: 45px;
                        min-height: 45px;
                        background-color: $basePrimaryColor;
                        color: $baseSecondaryColor;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        border-radius: 50%;

                        font-size: 22px;
                    }

                    .instruction-item-text {
                        font-size: 14px;
                    }
                }
            }

            .instruction-warning {
                display: flex;
                align-items: flex-start;
                gap: 15px;
                font-size: 14px;
            }
        }
    }

    .order-instruction-btn-container {
        .order-instruction-btn {
            font-weight: 500;
            font-size: 18px;
        }
    }

    .order-instruction-footer {
        width: 100%;
        text-align: center;
        color: $basePrimaryColor;
    
        padding: 0 8rem;
    }
}

@media (max-width: 778px) {
    .order-instruction {
        padding: 4rem 1rem 4rem 1rem;

        .order-instruction-header {
            flex-direction: row-reverse;
            gap: 25px;
            
            .order-instruction-header-content {
                .order-instruction-desc {
                    text-align: justify;
                    text-justify: none;
                }
            }
        }

        .order-instruction-content {
            flex-direction: column;
        
            .order-instruction-content-instruction {
                padding: 20px 0;

                .instruction-list {
                    .instruction-item {
                        .instruction-item-text {
                            text-align: justify;
                            text-justify: none;
                        }
                    }
                }

                .instruction-warning {
                    .instruction-warning-text {
                        text-align: justify;
                        text-justify: none;
                    }
                }
            }
        }

        .order-instruction-btn-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .order-instruction-btn {
                width: 70%;
                font-weight: 700;
            }
        }

        .order-instruction-footer {
            padding: 0;
            font-size: 18px;

            p {
                // text-align: justify;
            }
        }
    }
}

@media (max-width: 520px) {
    .order-instruction {
        .order-instruction-header {
            .order-instruction-header-content {
                gap: 10px;
            }
        }
    }
}