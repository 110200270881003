$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);
$baseWhiteColor: var(--base-light-color);

.variant-showcase-container {
    width: 100%;

    .react-multiple-carousel__arrow {
        background-color: $baseWhiteColor;
        box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.31);

        &::before {
            color: $basePrimaryColor;
            font-weight: 600;
        }

        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .variant-showcase-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 85%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;

        img {
            width: 100%;
        }

        .variant-box {
            position: absolute;
            background-color: $baseShadeColor;
            color: $baseSecondaryColor;
            padding: 7px 11px;
            font-size: 10px;
            border-radius: 50px;
            text-transform: uppercase;

            bottom: 20px;
        }
    }

    // @media (max-width: 1200px) {
    //     // width: 100%;

    //     .variant-showcase-holder {
    //         img {
    //             width: 80%;
    //         }
    //     }
    // }

    @media (max-width: 900px) {
        .variant-showcase-holder {
            img {
                width: 100%;
            }
        }
    }
}