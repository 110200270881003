$baseDarkColor: var(--base-dark-color);
$baseGrayColor: var(--grey-color-4);

$baseLightColor: var(--base-light-color);

$baseSuccessColor: var(--base-success-color);
$baseDangerColor: var(--base-danger-color);

$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

.shopping-cart-section {
    min-width: 600px;
    width: 55%;
    padding-top: 60px;
    padding-left: 2rem;

    padding-bottom: 50px;
    margin-bottom: 20px;

    .shopping-cart-title {
        font-weight: 800;
        color: $basePrimaryColor;
        margin-bottom: 60px;
    }

    .shopping-cart-subtitle {
        font-weight: 700;
        font-family: 'Inter';
        font-size: 17px;
    }

    .shopping-cart-caption {
        font-size: 17px;
    }

    .shopping-cart-table {
        width: 100%;
        margin-top: 20px;

        thead {
            border-bottom: 1px solid $baseGrayColor;
            
            tr {
                th { 
                    font-family: 'Inter';
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
        }

        tbody {
            // display: block;
            overflow: auto;
            max-height: 500px;
            font-size: 0.9rem;
        }

        tr {
            th {
                padding: 8px 0;
            }

            td {
                padding: 8px 0;

                &.product-photo {
                    width: 12%;
                    padding-right: 18px;

                    img {
                        width: 100%;
                        aspect-ratio: 1/1;
                        border-radius: 50%;
                    }
                }

                &.product-name {
                    width: 47%;
                    padding-right: 8px;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .name {
                        font-weight: 700;
                        font-size: 14px;
                    }

                    .variant {
                        font-size: 11px;
                    }
                }

                &.product-qty {
                    width: 15%;
                    margin: 0 auto;
                    text-align: center;
                    
                    & > .product-qty-counter {
                        height: 32px;

                        .quantity-input {
                            font-size: 12px;
                        }
                    }
                }

                &.product-price {
                    width: 20%;
                    margin: 0 auto;
                    text-align: right;

                    .price {
                        font-weight: 600;

                        &.discounted {
                            font-size: 12px;
                            text-decoration-line: line-through;
                            text-decoration-color: $baseDangerColor;
                            -webkit-text-decoration-line: line-through;
                            -webkit-text-decoration-color: $baseDangerColor;
                            display: block;
                        }
                    }
                }   
            }


            &.total-info {
                border-top: 1.5px solid $baseGrayColor;
                border-bottom: 1.5px solid $baseGrayColor;
                
                &.bold {
                    td {
                        font-weight: 700;
                    }
                }

                td {
                    padding: 10px 0;
                }

                td.total-value {
                    text-align: right;

                    &.total-free {
                        color: $baseSuccessColor;
                        font-weight: 700;
                    }
                }
            }
        }

    }

    @media screen and (max-width: 1275px) {
        min-width: 0;
        // width: 100%;
    }
    
    @media screen and (max-width: 1035px) {
        width: 100%;
        padding-right: 2rem;
    }

    @media screen and (max-width: 768px) {
        overflow-y: visible;
        overflow-x: auto;
        min-height: 200px;

        .shopping-cart-table {
            min-width: 576px;

            thead {
                tr {
                    th { 
                        font-size: 1.2rem;
                    }
                }
            }
    
            tbody {
                // display: block;
                overflow: auto;
                max-height: 500px;
                font-size: 1rem;
            }
    
            tr {
                td {
                    padding: 7px 0;
                    font-size: 14px;
    
                    &.product-photo {
                        width: 12%;
                        padding-right: 15px;
    
                        img {
                            width: 100%;
                        }
                    }
    
                    // &.product-name {
                    //     // width: 30%;
                    // }
    
                    &.product-size {
                        width: 15%;
                    }
    
                    &.product-qty {
                        width: 20%;

                        .product-qty-counter {
                            &.sm {
                                width: 100px;
                                height: 25px;
                        
                                .btn-toggle {
                                    font-size: 14px;
                                }

                                .quantity-input {
                                    width: 50px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
    
                    &.product-price {
                        width: 17%;
                    }   
                }
            }
        }
    }
}

.shopping-cart-section-empty {
    min-height: 600px;
}