$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);
$baseLightColor: var(--base-light-color);

$baseSuccessColor: var(--base-success-color);

.order-detail {
    display: flex;
    flex-direction: column;
    gap: 15px;
    
    .order-detail-header {
        margin-bottom: 15px;

        .order-detail-header-title {
            font-weight: 800;
            color: $basePrimaryColor;
            margin-bottom: 50px;
        }

        .order-detail-header-desc {
            font-weight: 700;
        }
    }

    .order-detail-payment-info {
        width: 50%;
        max-width: 600px;

        .payment-info-item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.total {
                span {
                    font-weight: 700;
                }
            }

            .value {
                font-weight: 400;

                &.free {
                    font-weight: 700;
                    color: $baseSuccessColor;
                }
            }
        }
    }

    .order-detail-footer {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 20px;

        .footer-instruction {
            max-width: 615px;
        }

        .btn-proceed {
            width: 250px;
            padding: 15px 12px;
            text-transform: capitalize;
            font-weight: 500;
            color: $baseLightColor;
            background-color: $basePrimaryColor;
            border-color: $basePrimaryColor;
        }
    }
}