.product-detail {
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
    gap: 30px;

    &.full-page {
        
        .product-content {

            .product-content-header {
        
                .product-name {
                    font-weight: 800;
                }
            }
        }
    }

    @media (max-width: 990px) {
        flex-direction: column;
        // gap: 3rem;
    }
}