$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseShadeColor: var(--base-shade-color);

$primaryTextColor: var(--primary-text-color);

$whatsappColor1: #FFF;
$whatsappColor2: $baseColor1;

$whatsappColorHover1: #dcf8c6;
$whatsappColorHover2: $baseShadeColor;

.btn-pre-order {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    text-decoration: none;
    font-weight: 400;
    background: $whatsappColor1;
    color: $whatsappColor2;

    border-radius: 20px;
    box-shadow:  0px 11px 20px 2px rgba(0, 54, 165, 0.1);

    .btn-pre-order-icon {
        width: 40px;
        height: 40px;
    }

    transition: all 0.5s ease-in-out;
    
    &:hover, &:focus, &:active {
        background: $whatsappColorHover1;
        color: $whatsappColorHover2;
    }

    .btn-pre-order-text {
        display: flex;
        flex-direction: column;

        .primary-text {
            font-family: Barlow;
            font-size: 19px;
            font-weight: 700;
        }

        .secondary-text {
            font-family: Inter;
            font-weight: 400;
            color: #000;
            font-size: 10px;
        }
    }

    &.is-fixed {
        position: fixed;
        z-index: 1001;
        bottom: 20px;
        right: 20px;

        align-self: center;
        padding: 0.7rem;

        @media screen and (max-width: 768px) {
            text-align: center;
            // right: initial;
            // bottom: 4rem;
            // font-size: 15px;
            // border-radius: 50%;
            // width: 95%;
            margin: 0 10px;

            .btn-pre-order-text {
                // display: none;
            }

            .btn-pre-order-icon {
                width: 40px;
            }
        }
    }
}